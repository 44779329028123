import React, {Component} from "react";
import {formatQuery} from "../controllers/utilities/string-utils";
import {connect} from "react-redux";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import {environmentMode} from "../components/lib/ad-manage-api-lib";
import iconGg from "../images/gg-logo.svg";
import Lambda from "../controllers/utilities/aws-lambda";
import {getFullPath} from "../controllers/kaisaku-api";
import cookie from "react-cookies";

class GameSupportAtt extends Component {
    constructor(props, context) {
        super(props, context);

        this.params = formatQuery(props.location.search);
        this.state = {
            ready: false
        };
    }


    async componentDidMount() {
        if(!window.Tawk_API) {
            window.Tawk_API = {};
        }
        // const info = await this.gatherInfo();
        // console.log(info);

        window.Tawk_API.onLoad = async () => {
            this.setState({ready: true});
            // window.Tawk_API.hideWidget();
            const info = await this.gatherInfo();
            console.log(info);
            window.Tawk_API.setAttributes({
                ...info
            }, err => {
                console.log(err);
            });
            window.Tawk_API.maximize();
        };
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://embed.tawk.to/5dedfec643be710e1d212faf/default";
        if (environmentMode === "SANDBOX") {
            script.src = "https://tawk.to/chat/5f9faddfe019ee7748efeab5/default";
        }
        console.log(script.src);
        script.charset = "UTF-8";
        script.setAttribute("crossorigin", "*");
        document.body.appendChild(script);
    }

    async gatherInfo() {
        const data = {};
        data.username = this.params.username;
        data.attributionId = this.params.attributionId;
        return data;
    }

    render() {
        return (
            <React.Fragment/>
        );
    }
}

function mapStateToProps(state) {
    return {session: state.session};
}

export default connect(mapStateToProps)(GameSupportAtt);

